import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/me/aaa/github/personalsite/src/components/post_template.js";
import ImageSource from "src/components/image";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`WRKS`}</h1>
    <p>{`A platform connecting job-seekers to companies based in the construction and real-estate industry.`}</p>
    <p>{`Developed the landing page using React.js, Webpack, and `}<a parentName="p" {...{
        "href": "https://github.com/postcss/postcss"
      }}>{`PostCSS`}</a>{`.`}</p>
    <br />
    <hr />
    <br />
    <ImageSource src="wrks/landing.png" alt="Landing page" mdxType="ImageSource" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      